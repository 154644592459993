.center{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;      
    text-align: center;
}


/*.center div{
    width: 100px;
    height: 100px;
} */
/* 
.box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
} */
